<template>
    <div class="sell-point">
        <b-card>
            <!--page details sellpoint-->
            <template #header>
                <!-- {{ id }} -->
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="users-alt"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات نقطة البيع</h4>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                    <span class="mr-1">حالة الحساب</span>
                    <b-badge
                        pill
                        :variant="
                            dtoUpdateSellPoint.isBlocked ? 'danger' : 'success'
                        "
                    >
                        {{ dtoUpdateSellPoint.isBlocked ? " محظور" : " مفعل" }}
                    </b-badge>
                </div>
            </template>
            <validationObserver ref="sellPointForm">
                <b-row>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="الاسم والكنبة"
                            label="الاسم والكنية"
                            v-model="dtoUpdateSellPoint.name"
                            :rules="[
                                { type: 'required', message: 'اسم مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="رقم الهاتف"
                            label="رقم الهاتف"
                            v-model="dtoUpdateSellPoint.phoneNumber"
                            placeholder="********"
                            :rules="[
                                { type: 'required', message: 'رقم مطلوب' },
                                {
                                    type: 'mobile',
                                    message: 'رقم الهاتف غير صالح',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="كلمة السر الجديدة"
                            label="كلمة السر الجديدة"
                            v-model="dtoUpdateSellPoint.newPassword"
                            :rules="[
                                {
                                    type: 'min:8',
                                    message:
                                        'كلمة المرور يجب ان تكون اطول من 8 محارف',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="نسبة نقطة البيع"
                            label="نسبة نقطة البيع"
                            v-model="dtoUpdateSellPoint.rate"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'نسبة نقطة البيع مطلوبة',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="8" md="6">
                        <ek-input-select
                            name="sendTo"
                            multiple
                            placeholder="حدد  نوع الكلية"
                            label="الكليات التابعة لنقطة البيع  "
                            textLabel="followTo"
                            :options="facultyName"
                            v-model="dtoUpdateSellPoint.facultyIds"
                            :rules="[
                                {
                                    type: 'required',
                                    message: ' الكلية مطلوبة',
                                },
                            ]"
                        ></ek-input-select>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="العنوان"
                            label="العنوان"
                            placeholder="ادخل العنوان"
                            v-model="dtoUpdateSellPoint.address"
                            :rules="[
                                { type: 'required', message: 'العنوان مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            readonly
                            name="عدد الاكواد "
                            label="عدد الرموز المولدة"
                            v-model="dtoUpdateSellPoint.codesCount"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            readonly
                            name="عدد الاكواد "
                            label="عدد الرموز المحاسب عليها"
                            v-model="dtoUpdateSellPoint.codesPaid"
                        ></ek-input-text>
                    </b-col>
                    <!-- <b-col lg="4" md="6">
            <ek-input-text
              name="حد الحسم"
              label="حد الحسم"
              v-model="dtoUpdateSellPoint.discountLimit"
            ></ek-input-text>
          </b-col> -->
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="حد الاعظمي للبيع"
                            label="حد الاعظمي للبيع"
                            v-model="dtoUpdateSellPoint.moneyLimit"
                            prefix="ل.س" 
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <b-form-group label="امكانية الحسم">
                            <b-form-radio-group
                                id="radio-slots"
                                :options="options"
                                v-model="dtoUpdateSellPoint.canDiscount"
                                :aria-describedby="ariaDescribedby"
                                name="امكانية الحسم"
                            >
                            </b-form-radio-group>
                        </b-form-group>

                        <ek-input-text
                            v-if="dtoUpdateSellPoint.canDiscount"
                            type="number"
                            placeholder="ادخل  حد الحسم"
                            name="حد الحسم"
                            label="حد الحسم"
                            v-model="dtoUpdateSellPoint.discountLimit"
                            :rules="[
                                {
                                    type: 'required',
                                    message:
                                        '  حد الحسم مطلوب وحصراً قيمة موجبة',
                                },
                            ]"
                                          prefix="%" 
                        ></ek-input-text>
                    </b-col>
                </b-row>
            </validationObserver>
        </b-card>
        <!--table codes-->
        <ek-table
            :title="`الرموز المولدة`"
            :columns="columnsCode"
            :items="dtoUpdateSellPoint.codes"
            @details="goToDetails"
            @delete-selected="deleteCode"
        >
            <template slot="items.endDate" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>
            <template slot="items.startDate" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>
            <template slot="items.isActive" slot-scope="{ value }">
                <StatusBadge :value="value" :options="statuscode" />
            </template>

            <template slot="items.subjetsNames" slot-scope="{ props }">
                <b-button
                    :id="`popover-target-${props.row.originalIndex}`"
                    variant="transperant"
                >
                    مرر لرؤية المواد
                </b-button>
                <b-popover
                    :target="`popover-target-${props.row.originalIndex}`"
                    triggers="hover"
                    placement="right"
                >
                    <span>
                        {{
                            props.row.subjetsNames.length > 0
                                ? props.row.subjetsNames.find((ele) => ele)
                                : "لايوجد"
                        }}
                    </span>
                </b-popover>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";

export default {
    data() {
        return {
            options: [
                { text: "مفعل", value: true },
                { text: "غير مفعل ", value: false },
            ],
        };
    },
    props: ["id"],
    components: {
        StatusBadge,
    },
    computed: {
        ...mapState({
            sellPointsList: (state) => state.sellPoints.sellPointsList,
            dtoUpdateSellPoint: (state) => state.sellPoints.dtoUpdateSellPoint,
            columnsCode: (state) => state.sellPoints.columnsCode,
            sellPointStatusList: (state) => state.sellPoints.sellPointStatus,
            rateDto: (state) => state.settings.rateDto,
            facultyName: (state) => state.faculty.facultyName,
            statuscode: (state) => state.sellPoints.statuscode,
        }),
    },

    methods: {
        goToDetails({ row }) {
            this.$router.push(`/admin/codes/${row.packageId}`);
        },
        ...mapActions([
            "getByIdSellPoint",
            "updateSellPoint",
            "blockSellPoint",
            "unblockSellPoint",
            "getFacultyName",
            "deleteCode",
        ]),
    },

    created() {
        this.getByIdSellPoint(this.id);
        this.getFacultyName();
    },
};
</script>

<style lang="scss">
.sell-point {
    .card-header {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }
    .card-footer {
        border: none !important;
    }
}
</style>
